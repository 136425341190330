import dynamic from 'next/dynamic';

import { useFavorites, useGlobal } from '@hultafors/solidgear/hooks';

import StarYellowSvg from '../../svg/star-yellow.svg';
import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';
import { Product } from '../product/product';

import { HeaderInner } from './favorites.styled';

const SlideOut = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.SlideOut),
);

const SlideOutHeader = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.SlideOutHeader),
);

const ClearButton = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.ClearButton),
);

interface FavoritesProps {
  isOpen: boolean;
  toggleMenu(...props: any[]): void;
  header?: string | null;
  clearText?: string | null;
  rrpLabel?: string | null;
  rrpIncludingVatLabel?: string | null;
  partnerLoginLabel?: string | null;
  className?: string;
  isSmall?: boolean;
}

export const Favorites: React.FC<FavoritesProps> = ({
  toggleMenu,
  isOpen,
  header = '',
  clearText = '',
  rrpLabel = '',
  rrpIncludingVatLabel = '',
  className,
}) => {
  const { favorites, deleteFavorites } = useFavorites();

  const { global } = useGlobal();

  const onClick = () => toggleMenu(false);

  const slideOutHeader = (
    <SlideOutHeader onClick={onClick} closeLabel={global.close || ''}>
      <HeaderInner>
        <StarYellowSvg
          aria-hidden={true}
          focusable={false}
          width={16}
          height={16}
        />
        {header}
        <ClearButton
          onClick={deleteFavorites}
          disabled={!favorites?.length}
          title={clearText || ''}
        />
      </HeaderInner>
    </SlideOutHeader>
  );

  return (
    <SlideOut isOpen={isOpen} toggle={toggleMenu} header={slideOutHeader}>
      <Grid
        columnGap={[{ columnGap: 1 }]}
        columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 1 }]}
      >
        {favorites.map((product, i) => {
          return (
            <GridChild
              key={`Favorite-${i}`}
              columnSpan={[
                { columns: 1 },
                { breakpoint: 'mobileMax', columns: 1 },
              ]}
            >
              <Product
                product={product}
                inFavorites={true}
                rrpLabel={rrpLabel}
                rrpIncludingVatLabel={rrpIncludingVatLabel}
                noHover
              />
            </GridChild>
          );
        })}
      </Grid>
    </SlideOut>
  );
};
